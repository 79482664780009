<template>
  <div>
    <v-data-table
      :headers="cabecalhoPorTipoContrato()"
      :items="tipoContrato.contratos"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 20],
      }">
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.nome">
            <td style="width: 2%; text-align: center;">
              <v-menu bottom
                origin="center center"
                transition="scale-transition">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on"
                    icon
                    text>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="true"
                    @click="detalharContrato(item.id)">
                    <v-list-item-action>
                      <v-icon>details</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.detalhe', 2) }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="contratoVigente(item) && divisaoUsuario(item)"
                    @click="encerrar(item.id)">
                    <v-list-item-action>
                      <v-icon>event_busy</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('label.encerrar_contrato', 2) }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
            <td v-for="col in colunasPorTipoContrato()" :key="col">
              <status
                v-if="col === 'status'"
                :status="item.status"
                :tooltip="item.status">
              </status>
              <span v-else-if="col === 'divisao'">
                <v-menu open-on-hover
                  offset-x
                  top
                  :nudge-width="300"
                  :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{item.divisao}}
                    </span>
                  </template>
                  <v-card class="PlanejamentoContratoListTabela__Hier">
                    <v-card-title>
                      {{$tc('label.hierarquia', 1)}}
                    </v-card-title>
                    <v-card-text>
                      <v-treeview
                        open-all
                        open-on-click
                        :items="item.treeDivisao"
                        />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </span>
              <span v-else>
                {{formataValorColuna(col, item)}}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <planejamento-acao-justificativa
      ref="modalJustificativa"
      :obrigatorio="justificativaObrigatoria"
      :salvarJustificativa="actionJustificativa"
      :observacao="justificativa"
      :somenteLeitura="justificativaSomenteLeitura"
    >
    </planejamento-acao-justificativa>

    <confirm
      ref="confirmDialog"
      :persistent="true">
    </confirm>

    <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"
    >
    </acompanhamento>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PlanejamentoAcaoJustificativa from '@/spa/planejamento-acao/PlanejamentoAcaoJustificativa';
import BotoesDadosTabelaMixin from '@/spa/planejamento-contrato-suzano/list/BotoesDadosTabelaMixin';
import { buscarContratos } from '@/common/resources/planejamento-contrato-suzano';
import { generateComputed } from '../../../produto/common/functions/roles-computed-generator';
import { getMoney } from '../../../produto/common/functions/helpers';
import Status from '../../../produto/shared-components/Status';
import Confirm from '../../../produto/shared-components/vuetify/dialog/Confirm';
import Acompanhamento from '../../../produto/shared-components/workflow/Acompanhamento';
import { skipLoading } from '../../../produto/common/functions/loading';

export default {
  components: {
    Status,
    Confirm,
    PlanejamentoAcaoJustificativa,
    Acompanhamento,
  },
  mixins: [BotoesDadosTabelaMixin],
  computed: {
    ...mapGetters('metadados', [
      'getContratoMetadado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...generateComputed('CONTRATO', [
      'canEdit',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    canCancelarContrato() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CANCELAR_CONTRATO').length;
    },
    metadadosContrato() {
      if (this.getContratoMetadado && this.getContratoMetadado.mapaEntidades) {
        return this.getContratoMetadado;
      }
      return undefined;
    },
    divisao() {
      return this.usuarioLogado.divisao ? this.usuarioLogado.divisao.split(',') : [];
    },
  },
  props: {
    tipoContrato: Object,
    ordenacao: Array,
    filtros: Object,
    agrupamento: String,
    labelCampoCliente: {
      type: String,
      default: '',
    },
  },
  watch: {
    pagination: {
      handler(pagination) {
        if (this.mesmaPagina(pagination, this.lastPagination)) {
          return;
        }
        this.lastPagination = pagination;
        this.buscarContratos();
      },
      deep: true,
    },
  },
  data() {
    return {
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      workflowContratoResource: this.$api.workflowContrato(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoCadastro(this.$resource),

      pagination: {
        rowsPerPage: 10,
      },
      totalPage: 0,
      lastPagination: {},
      cabecalhoFixo: [
        {
          text: this.$tc('label.codigo', 1),
          value: 'codigo',
          sortable: false,
        },
        { text: this.$tc('label.cliente_customer', 1), value: 'cliente_customer', sortable: false },
        { text: this.$tc('label.data_inicio', 1), value: 'data_inicio', sortable: false },
        { text: this.$tc('label.data_vencimento_revisao', 1), value: 'data_vencimento_revisao', sortable: false },
        { text: this.$tc('label.descricao', 1), value: 'descricao', sortable: false },
        { text: this.$tc('label.hierarquia1', 1), value: 'hierarquia1', sortable: false },
        {
          text: this.$tc('label.acoes', 2), value: 'acoes', sortable: false, width: '2%', align: 'center',
        },
        { text: this.$tc('label.divisao', 1), value: 'divisao', sortable: false },
        { text: this.$tc('label.status', 1), value: 'status', sortable: false },
      ],

      justificativaObrigatoria: false,
      actionJustificativa() {},
      contratoSelecionada: {},
      justificativa: '',
      justificativaSomenteLeitura: false,
      statusPassos: {},
    };
  },
  methods: {
    getMoney,
    mesmaPagina(p1, p2) {
      return p1.page === p2.page && p1.itemsPerPage === p2.itemsPerPage;
    },
    contratoVigente(contrato) {
      const { status } = contrato;
      return status.toUpperCase() === 'VIGENTE';
    },
    divisaoUsuario(contrato) {
      return this.divisao.includes(contrato.id_divisao.toString());
    },
    buscarContratos() {
      const params = {
        ...this.filtros,
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
      };

      params.id_contrato_tipo = this.tipoContrato.id;

      if (this.tipoContrato.idAgrupamento) {
        params.id_agrupamento = this.tipoContrato.idAgrupamento;
        params.agrupamento = this.agrupamento;
      }

      buscarContratos(params, this.$resource)
        .then((res) => {
          this.totalPage = res.data.quantidadeRegistrosPagina;
          this.tipoContrato.contratos = [...res.data.resposta];
          this.tipoContrato.contratos.forEach((c) => {
            this.verificarUsuarioAprovador(c);
            this.verificarUsuarioSolicitante(c);
            // this.buscarStatusFluxo(c);
            this.montaArvoreInvertidaDivisao(c);
          });
          setTimeout(() => this.$forceUpdate());
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    montaArvoreInvertidaDivisao(contrato) {
      const { hier } = contrato;
      if (!hier || hier.length === 0) {
        return;
      }

      const itemHier = (name, children) => ({ name, children });

      const converteArvore = (children) => {
        children = children.map((c) => {
          const item = itemHier(c.nome, converteArvore(c.filhos));
          if (item.children.length === 0) {
            item.disabled = true;
          }
          return item;
        });
        return children;
      };

      const tree = hier.map((h) => {
        const base = itemHier(h.nome, h.filhos);
        base.children = converteArvore(base.children);
        return base;
      });

      contrato.treeDivisao = tree;
    },
    contratoCancelado(contrato) {
      const { status } = contrato;
      return status === 'CANCELADO';
    },
    editarContrato(idContrato) {
      this.$router.push({ name: 'editarContratoSuzano', params: { idContrato } });
    },
    detalharContrato(idContrato) {
      this.$router.push({ name: 'detalharContratoSuzano', params: { idContrato, from: 'planejamento-contrato-suzano' } });
    },
    cabecalhoPorTipoContrato() {
      const { tipoContrato } = this;
      if (tipoContrato.cabecalho) return tipoContrato.cabecalho;

      const ordenacao = this.colunasPorTipoContrato(),
        cabecalho = [];

      ordenacao.forEach((col) => {
        let isColFixa = false;
        for (let i = 0; i < this.cabecalhoFixo.length; i += 1) {
          const defCol = this.cabecalhoFixo[i];
          isColFixa = defCol.value === col;
          if (isColFixa) {
            if (defCol.value === 'campo_cliente') {
              defCol.text = `${this.$tc('label.foco_contrato', 1)} - ${this.labelCampoCliente}`;
            }
            cabecalho.push(defCol);
            break;
          }
        }
        if (!isColFixa) {
          cabecalho.push({
            text: this.tituloCampoDinamico(col),
            value: col,
            sortable: false,
          });
        }
      }, this);

      const colAcoes = this.cabecalhoFixo.filter((c) => c.value === 'acoes')[0];
      tipoContrato.cabecalho = [colAcoes, ...cabecalho];

      return tipoContrato.cabecalho;
    },
    colunasPorTipoContrato() {
      return ['codigo', 'cliente_customer', 'divisao', 'descricao', 'hierarquia1', 'data_inicio', 'data_vencimento_revisao', 'status'];
    },
    tituloCampoDinamico(nomeCampo) {
      const dependencia = this.getDepenciaMetadadosPorNome(nomeCampo);
      if (dependencia != null) {
        return dependencia.entidadeEstrangeira;
      }
      const campoDinamico = this.getCampoDinamicoPorNome(nomeCampo);
      if (!campoDinamico) {
        $logger.log(nomeCampo);
      }
      return campoDinamico.nomCampo;
    },
    formataValorColuna(nomeColuna, item) {
      return item[nomeColuna];
    },
    ehCampoDinheiro(coluna) {
      const camposDinheiro = ['valor_consumido', 'saldo', 'valor'];
      return camposDinheiro.indexOf(coluna) >= 0;
    },
    extraiNomeExtensao(valorConcatenado) {
      const valores = valorConcatenado.split('|'),
        nomExtensao = valores[valores.length - 1];
      return nomExtensao;
    },
    ehDependenciaExtensao(nomeCampo) {
      return this.getDepenciaMetadadosPorNome(nomeCampo) != null;
    },
    getDepenciaMetadadosPorNome(nomeCampo) {
      const dependencias = this.metadadosContrato.mapaEntidades,
        dependencia = dependencias[nomeCampo];
      return dependencia;
    },
    getCampoDinamicoPorNome(nomeCampo) {
      const campos = this.metadadosContrato.mapaCamposDinamicos,
        campo = campos[nomeCampo];
      return campo;
    },
    encerrar(idContrato) {
      this.$refs.confirmDialog
        .openWithParams(
          this.$t('title.atencao'),
          this.$t('message.deseja_encerrar_contrato_manual'),
          (ok) => {
            if (ok) {
              const idApuracao = idContrato;
              const observacao = this.$t('message.contrato_acao_massivo.observacao_encerrar_contrato');
              this.workflowApuracaoAcaoResource.cancelar({ idApuracao },
                { observacao })
                .then(() => {
                  this.$toast(this.$t('message.contrato_encerrar'));
                  this.buscarContratos();
                })
                .catch((err) => {
                  this.$error(this, err);
                });
            }
          },
        );
    },
    verificarUsuarioAprovador(contrato) {
      if (contrato.status === 'AGUARDANDO_APROVACAO') {
        const idContrato = contrato.id;
        this.workflowContratoResource.aprovador({ idContrato })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              contrato.aprovadorFluxo = true;
              this.$forceUpdate();
            }
          });
      }
    },
    verificarUsuarioSolicitante(contrato) {
      if (contrato.status === 'EM_CADASTRO'
          || contrato.status === 'AGUARDANDO_APROVACAO'
          || contrato.status === 'EM_ANALISE'
          || contrato.status === 'REPROVADO') {
        const idContrato = contrato.id;
        skipLoading();
        this.workflowContratoResource.solicitante({ idContrato })
          .then((response) => {
            contrato.solicitanteFluxo = response.data.usuarioHabilitado;
            this.$forceUpdate();
          });
      }
    },
    buscarStatusFluxo(contrato) {
      if ((contrato.status === 'REPROVADO' || contrato.status === 'EM_ANALISE')
        && contrato.ind_possui_fluxo_aprovacao && contrato.tem_fluxo
        && !contrato.tem_fluxo_aprovado) {
        const idContrato = contrato.id;
        this.workflowContratoResource.status({ idContrato })
          .then((response) => {
            contrato.fluxo = response.data;
          })
          .catch((err) => {
            this.$error(this, err);
          });
      }
    },
    abrirJustificativa(contrato) {
      this.justificativa = contrato.fluxo.passoAtual.observacao;
      this.justificativaSomenteLeitura = true;
      setTimeout(() => this.$refs.modalJustificativa.open());
    },
    mostrarAcompanhamento(item) {
      return item.status !== 'EM_CADASTRO' && item.status !== 'CANCELADO';
    },
    temFluxo(item) {
      return item.ind_possui_fluxo_aprovacao && item.tem_fluxo;
    },
    abrirAcompanhamento(item) {
      const idContrato = item.id;
      this.workflowContratoResource
        .existeFluxoCadastrado({ idContrato })
        .then((res) => {
          const existeFluxo = res.data;
          if (!existeFluxo) {
            const msg = this.$t('message.contrato_finalizado_sem_fluxo');
            this.$toast(msg);
            return;
          }
          this.exibeModalAcompanhamento(idContrato);
        });
    },
    exibeModalAcompanhamento(idContrato) {
      this.workflowContratoResource.statusPasso({ idContrato }, { idContrato })
        .then((res) => {
          this.statusPassos = res.data;
          setTimeout(() => this.$refs.modalAcompanhamento.open());
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
  },
};
</script>
<style>
.PlanejamentoContratoListTabela__ColunaGrande {
  min-width: 210px;
}
.PlanejamentoContratoListTabela__ColunaMedia {
  min-width: 120px;
}
</style>
