var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.cabecalhoPorTipoContrato(),"items":_vm.tipoContrato.contratos,"options":_vm.pagination,"server-items-length":_vm.totalPage,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
      itemsPerPageOptions: [5, 10, 20],
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
    var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.nome},[_c('td',{staticStyle:{"width":"2%","text-align":"center"}},[_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',[(true)?_c('v-list-item',{on:{"click":function($event){return _vm.detalharContrato(item.id)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("details")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc('label.detalhe', 2)))])],1):_vm._e(),(_vm.contratoVigente(item) && _vm.divisaoUsuario(item))?_c('v-list-item',{on:{"click":function($event){return _vm.encerrar(item.id)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("event_busy")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc('label.encerrar_contrato', 2)))])],1):_vm._e()],1)],1)],1),_vm._l((_vm.colunasPorTipoContrato()),function(col){return _c('td',{key:col},[(col === 'status')?_c('status',{attrs:{"status":item.status,"tooltip":item.status}}):(col === 'divisao')?_c('span',[_c('v-menu',{attrs:{"open-on-hover":"","offset-x":"","top":"","nudge-width":300,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.divisao)+" ")])]}}],null,true)},[_c('v-card',{staticClass:"PlanejamentoContratoListTabela__Hier"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$tc('label.hierarquia', 1))+" ")]),_c('v-card-text',[_c('v-treeview',{attrs:{"open-all":"","open-on-click":"","items":item.treeDivisao}})],1)],1)],1)],1):_c('span',[_vm._v(" "+_vm._s(_vm.formataValorColuna(col, item))+" ")])],1)})],2)}),0)]}}])}),_c('planejamento-acao-justificativa',{ref:"modalJustificativa",attrs:{"obrigatorio":_vm.justificativaObrigatoria,"salvarJustificativa":_vm.actionJustificativa,"observacao":_vm.justificativa,"somenteLeitura":_vm.justificativaSomenteLeitura}}),_c('confirm',{ref:"confirmDialog",attrs:{"persistent":true}}),_c('acompanhamento',{ref:"modalAcompanhamento",attrs:{"fluxo":_vm.statusPassos}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }