<template>
  <v-card style="margin-bottom:1em" class="PlanejamentoContratoFiltros">
    <v-card-title>
      <span class="title">{{ $tc('title.filtro', 2) }}</span>

      <v-spacer></v-spacer>
      <icon-filtro @click="toggleFiltros" :filtros="filtrosPlanejamentoContrato" />
    </v-card-title>

    <v-container fluid grid-list-md v-show="filtrosAbertos">
      <metadados-container-layout
        :ordenacao-campos="ordenacaoCampos"
        :campos-formulario="camposFormulario"
        :explode-hierarquia="true"
        :formulario-filtros="true"
        :ignora-obrigatorios="true"
        :layout-class="layoutClass"
        :input-layout="inputLayout"
        :objeto="filtrosPlanejamentoContrato"
        ref="container">
      </metadados-container-layout>
    </v-container>
    <v-card-actions v-show="filtrosAbertos">
      <v-spacer></v-spacer>
      <v-btn @click="resetaFiltros"
        color="accent"
      >{{$t('label.limpar_filtros')}}</v-btn>
      <v-btn @click="aplicarFiltros"
        color="primary"
      >{{$t('label.filtrar')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { skipLoading } from '../../../produto/common/functions/loading';
import { copyObject } from '../../../produto/common/functions/helpers';

import MetadadosContainerLayout from '../../../produto/shared-components/metadados/MetadadosContainerLayout';
import PlanejamentoContratoListFiltrosCampos from './PlanejamentoContratoListFiltrosCampos';
import IconFiltro from '../../../produto/shared-components/filtros/IconFiltro';

export default {
  name: 'PlanejamentoContratoFiltros',
  mixins: [
    PlanejamentoContratoListFiltrosCampos,
  ],
  components: {
    MetadadosContainerLayout,
    IconFiltro,
  },
  computed: {
    ...mapGetters('metadados', [
      'getContratoMetadado',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
        'mr-2': true,
      };
    },
    usuarioCliente() {
      return this.usuarioLogado.tipo === 'CLIENTE';
    },
  },
  data() {
    return {
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      configuracaoResource: this.$api.planejamentoContratoConfiguracao(this.$resource),

      filtrosAbertos: false,
      filtrosFixados: false,
      carregouCampos: false,

      metadadosPlanejamentoContrato: null,

      ordenacaoCampos: [
        'codigo',
        'cliente',
        'holding',
        'divisao',
        'descricao',
        'data_inicio',
        'data_fim',
        'status',
        'hierarquia1',
        'hierarquia2',
        'hierarquia3',
        'produto',
      ],
      tipoFiltro: 'comum',

      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
      filtrosPlanejamentoContrato: {},
      workspace: {},
      tiposDeInvestimento: null,
      metadadosCamposGerais: null,
      entidadeWorkspace: 'planejamento_contrato',
    };
  },
  methods: {
    toggleFiltros() {
      this.filtrosAbertos = !this.filtrosAbertos;
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources
        .inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = this.entidadeWorkspace;
      this.workspaceFiltrosResources
        .atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    resetaFiltros() {
      this.filtrosPlanejamentoContrato = {};
      this.$refs.container.refresh();
      setTimeout(() => this.aplicarFiltros());
    },
    validarDatas(filtros) {
      const dataInicial = moment(filtros.data_inicio);
      const dataFinal = moment(filtros.data_fim);

      if (filtros.data_inicio !== undefined && filtros.data_fim === undefined) {
        this.$toast(this.$t('errors.dta_fim.obrigatoria'));
        return false;
      }

      if (dataFinal.isBefore(dataInicial)) {
        this.$toast(this.$t('message.data_inicial_maior_final'));
        return false;
      }
      return true;
    },
    restaurarFiltros() {
      this.pesquisaValida = this.validarDatas(this.filtrosPlanejamentoContrato);
      this.preparaParamsFiltros();
      if (this.pesquisaValida) {
        const parametros = {
          ...this.filtrosPlanejamentoContrato,
        };
        this.$emit('PlanejamentoContratoFiltros__AplicaFiltros', parametros);
      }
    },
    preparaParamsFiltros() {
      this.hierarquia1Format = [];
      this.hierarquia2Format = [];
      this.hierarquia3Format = [];
      this.produtoFormat = [];
      this.divisaoFormat = [];
      this.holdingFormat = [];
      this.clienteFormat = [];
      this.statusFormat = [];
      if (this.filtrosPlanejamentoContrato.hierarquia1 !== undefined) {
        this.filtrosPlanejamentoContrato.hierarquia1.forEach((hier1) => {
          this.hierarquia1Format.push(hier1.id);
        });
        this.filtrosPlanejamentoContrato.ids_hierarquia1 = this.hierarquia1Format;
      }
      if (this.filtrosPlanejamentoContrato.hierarquia2 !== undefined) {
        this.filtrosPlanejamentoContrato.hierarquia2.forEach((hier2) => {
          this.hierarquia2Format.push(hier2.id);
        });
        this.filtrosPlanejamentoContrato.ids_hierarquia2 = this.hierarquia2Format;
      }
      if (this.filtrosPlanejamentoContrato.hierarquia3 !== undefined) {
        this.filtrosPlanejamentoContrato.hierarquia3.forEach((hier3) => {
          this.hierarquia3Format.push(hier3.id);
        });
        this.filtrosPlanejamentoContrato.ids_hierarquia3 = this.hierarquia3Format;
      }
      if (this.filtrosPlanejamentoContrato.produto !== undefined) {
        this.filtrosPlanejamentoContrato.produto.forEach((prod) => {
          this.produtoFormat.push(prod.id);
        });
        this.filtrosPlanejamentoContrato.ids_produto = this.produtoFormat;
      }
      if (this.filtrosPlanejamentoContrato.divisao !== undefined) {
        this.filtrosPlanejamentoContrato.divisao.forEach((div) => {
          this.divisaoFormat.push(div.id);
        });
        this.filtrosPlanejamentoContrato.ids_divisao = this.divisaoFormat;
      }
      if (this.filtrosPlanejamentoContrato.holding !== undefined) {
        this.filtrosPlanejamentoContrato.holding.forEach((hol) => {
          this.holdingFormat.push(hol.id);
        });
        this.filtrosPlanejamentoContrato.ids_holding = this.holdingFormat;
      }
      if (this.filtrosPlanejamentoContrato.cliente !== undefined) {
        this.filtrosPlanejamentoContrato.cliente.forEach((cli) => {
          this.clienteFormat.push(cli.id);
        });
        this.filtrosPlanejamentoContrato.ids_cliente = this.clienteFormat;
      }
      if (this.filtrosPlanejamentoContrato.status !== undefined) {
        this.filtrosPlanejamentoContrato.status.forEach((st) => {
          this.statusFormat.push(st.valor);
        });
        this.filtrosPlanejamentoContrato.status_filtro = this.statusFormat;
      }
    },
    aplicarFiltros() {
      this.restaurarFiltros();
      this.salvarFiltrosWorkspace();
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosPlanejamentoContrato;
      this.workspace.filtros.tipoFiltro = this.tipoFiltro;

      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    preencheHoldings() {
      this.configuracaoResource
        .buscarHoldingsCliente()
        .then((res) => {
          if (this.filtrosPlanejamentoContrato.holding === null
                || this.filtrosPlanejamentoContrato.holding === undefined) {
            this.filtrosPlanejamentoContrato.holding = res.body;
            this.$refs.container.refresh();
          }
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    const metadados = this.getContratoMetadado;
    this.metadadosPlanejamentoContrato = copyObject(metadados);
  },
};
</script>
<style>
  .PlanejamentoContratoFiltros__Opcoes {
    padding: 0 0 0 20px;
    margin-top: 0;
  }
  .PlanejamentoContratoFiltros__Opcoes div.v-input__slot,
  .PlanejamentoContratoFiltros__Opcoes div.v-radio {
    margin-bottom: 0;
  }
  .PlanejamentoContratoFiltros__Opcoes div.v-messages {
    display: none;
  }
  .PlanejamentoContratoFiltros__Opcoes label {
    font-size: 14px;
  }
</style>
