<template>
  <v-container pb-0 px-0 pt-0 class="PlanejamentoContratoListDadosAgrupamento">
    <v-row align="center" justify="center" row fill-height>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import PlanejamentoContraloControleLayout from '@/spa/planejamento-contrato-suzano/list/PlanejamentoContraloControleLayout';

export default {
  data() {
    return {
      configuracaoResource: this.$api.planejamentoContratoConfiguracao(this.$resource),
      botoesAgrupamento: {},
      extensoes: [],
      dadosHolding: null,
      indiceLabelAgrupamento: null,
    };
  },
  mixins: [PlanejamentoContraloControleLayout],
  computed: {
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    ...mapGetters('metadados', [
      'getFornecedorMetadado',
    ]),
    dependenciasFornecedor() {
      if (!this.indModoVarejo || !this.getFornecedorMetadado) {
        return [];
      }

      const { mapaEntidades } = this.getFornecedorMetadado;
      return Object.values(mapaEntidades)
        .map((e) => ({ nome: e.entidadeEstrangeira, label: e.label }));
    },
  },
  methods: {
    buscarUltimoNivelExtensao(mnemonico) {
      this.configuracaoResource.buscarUltimoNivelExtensao({ mnemonico })
        .then((res) => {
          this.dadosHolding = res.data;
        });
    },
    refazBusca() {
      this.$emit('PlanejamentoContratoListDadosAgrupamento_Alterado');
    },
    agrupaPorTipoContrato() {
      this.desagrupaOutros('tipoContrato');
      this.refazBusca();
    },
    agrupaPorCliente() {
      this.desagrupaOutros('cliente');
      this.refazBusca();
    },
    agrupaPorFornecedor() {
      this.desagrupaOutros('fornecedor');
      this.refazBusca();
    },
    agrupaPorExtensao(labelExtensao, indiceRecursao) {
      this.desagrupaOutros(labelExtensao);
      this.indiceLabelAgrupamento = indiceRecursao;
      this.refazBusca();
    },
    desagrupaOutros(labelAgrupamento) {
      Object.keys(this.botoesAgrupamento)
        .filter((k) => k !== labelAgrupamento)
        .forEach((k) => {
          this.botoesAgrupamento[k] = false;
        });
      this.botoesAgrupamento[labelAgrupamento] = true;
      setTimeout(() => this.$forceUpdate());
    },
    botaoSelecionado(nomeBotao) {
      return this.botoesAgrupamento[nomeBotao];
    },
    habilitaPorTipoContrato() {
      this.botoesAgrupamento.tipoContrato = true;

      if (this.indModoVarejo) {
        this.botoesAgrupamento.fornecedor = false;
        this.extensoes.forEach((d) => {
          this.botoesAgrupamento[d.label] = false;
        });
      }

      setTimeout(() => this.$forceUpdate());
    },
    agrupamentoPadraoPorTipo() {
      this.botoesAgrupamento.tipoContrato = true;

      if (this.indModoVarejo) {
        this.botoesAgrupamento.fornecedor = false;
        this.extensoes.forEach((d) => {
          this.botoesAgrupamento[d.label] = false;
        });
      } else {
        this.botoesAgrupamento.cliente = false;
        this.botoesAgrupamento.holding = false;
      }

      setTimeout(() => this.$forceUpdate());
    },
    agrupamentoPadraoPorExtensao() {
      this.botoesAgrupamento.tipoContrato = false;

      if (this.indModoVarejo) {
        this.botoesAgrupamento.fornecedor = false;

        this.extensoes.forEach((d) => {
          this.botoesAgrupamento[d.label] = false;
        });

        const primeiraDependencia = this.extensoes[0];
        this.botoesAgrupamento[primeiraDependencia.label] = true;
      }

      setTimeout(() => this.$forceUpdate());
    },
    agrupamentoHabilitado() {
      const agrupamento = Object.keys(this.botoesAgrupamento)
        .filter((k) => this.botoesAgrupamento[k])[0];
      return agrupamento;
    },
    buscarExtensoes() {
      this.extensoes = this.dependenciasFornecedor;

      this.configuracaoResource
        .buscarLabelsCampoCliente()
        .then((res) => {
          res.data.forEach((campo) => {
            this.extensoes.push(campo);
          });
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  beforeMount() {
    this.buscarExtensoes();
    this.buscarUltimoNivelExtensao('HOLDING');
  },
};
</script>
<style>
.PlanejamentoContratoListDadosAgrupamento {
  width: 100%;
  max-width: initial;
}
.PlanejamentoContratoListDadosAgrupamento i,
.PlanejamentoContratoListDadosAgrupamento span {
  width: 100%;
}
.PlanejamentoContratoListDadosAgrupamento button {
  margin-right: 10px;
  padding: 4px;
  font-size: 0.7em;
}
.PlanejamentoContratoListDadosAgrupamento button.selecionado {
  color: var(--v-primary-darken1);
}
.PlanejamentoContratoListDadosAgrupamento button.selecionado i {
  color: var(--v-primary-darken1);
}
.PlanejamentoContratoListDadosAgrupamento button:not(.selecionado):hover {
  color: var(--v-grey-darken1);
}
.PlanejamentoContratoListDadosAgrupamento button:not(.selecionado):hover i {
  color: var(--v-grey-darken1);
}
</style>
